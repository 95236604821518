import { Collection, CollectionPrinter, Div, Link } from "@tblabs/truffle";
import { Field } from "../../../Models/Description/Field";
import { Opinion } from "../../../Models/Description/Opinion/Opinion";
import { FieldEditor } from "../../Utils/FieldEditor";
import { OpinionEdit } from "./OpinionEditor";
import { OpinionField } from "../../../Models/Description/Opinion/OpinionField";
import { AddFieldBox } from "../../Admin/AddFieldBox";


export class OpinionsEditor extends FieldEditor
{
    constructor(field: OpinionField, fields: Collection<Field>)
    {
        super("Opinie", field, fields, [
            new CollectionPrinter(field.Opinions, x => new OpinionEdit(x, field.Opinions)),
            new AddFieldBox(
                new Link("➕ Opinia").OnClick(() => field.Opinions.Add(new Opinion({ Author: "Anonim", Rate: 5 }))),
            ),
        ]);
    }
}
