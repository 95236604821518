
export enum TrainingStatus
{
    Unset = "Unset",
    Awaits = "Awaits",
    Undone = "Undone",
    Done = "Done",
    Available = "Available",
    AvailableSoon = "AvailableSoon",
    Unavailable = "Unavailable"
}
