import { Div } from "@tblabs/truffle";
import { Page } from "./Page";
import { Loader } from "../../Components/Utils/Loader";
import { WebsiteContent } from "../../Models/WebsiteContent/WebsiteContent";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";


export class WebsitePage extends Page
{
    private websiteContent: WebsiteContent;
    private whenReady: (content: WebsiteContent) => undefined = () => undefined;

    private loader = new Loader()
    private content = new Div()

    constructor(private __website: WebsiteContentProvider)
    {
        super();

        this.Append(
            this.loader,
            this.content
        )
    }

    protected async OnAppend(): Promise<void>
    {
        this.websiteContent = await this.__website.Get();

        this.loader.RemoveSelf();

        this.content.Append(
            this.whenReady?.(this.websiteContent)
        )
    }

    public WhenReady(handler: (content: WebsiteContent) => undefined)
    {
        this.whenReady = handler;
        return this;
    }
}
