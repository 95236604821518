import { RefString, RefNumber } from "@tblabs/truffle";
import { BasketItemType } from "../../Services/Basket/BasketItemType";
import { RawProductInfo } from "../OrderTicket/RawProductInfo";


export abstract class BasketItem
{
    public Type = BasketItemType.Unset;
    public ProductId = new RefString();
    public Quantity = new RefNumber(1);
    public Product: RawProductInfo;

    public abstract TypeAsString: string;

    constructor(productId?: string, quantity = 1)
    {
        if (productId)
            this.ProductId.value = productId;
        this.Quantity.value = quantity;
    }
}
