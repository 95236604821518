import { Collection, CollectionPrinter, Div, Link } from "@tblabs/truffle";
import { TableField } from "./TableField";
import { TableItemEditor } from "./TableItemEditor";
import { FieldEditor } from "../../Utils/FieldEditor";
import { Field } from "../../../Models/Description/Field";
import { TableItem } from "../../../Models/Description/Table/TableItem";


export class TableItemsEditor extends FieldEditor
{
    constructor(field: TableField, fields: Collection<Field>)
    {
        super("Tabela", field, fields, [
            new CollectionPrinter(field.Rows, x => new TableItemEditor(x, field.Rows)),
            new Div().ClearBoth(),
            new Link("➕ Wiersz").OnClick(() => field.Rows.Add(new TableItem({ Left: "", Right: "" }))),
        ]);
    }
}
