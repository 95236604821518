import { TableBuilder, Label, Link } from "@tblabs/truffle";
import { DynamicBasket } from "../Services/Basket/DynamicBasket";
import { DynamicBasketItem } from "../Services/Basket/DynamicBasketItem";
import { PlusMinusInput } from "./Inputs/PlusMinusInput";
import { ProductView } from "./Product/ProductView";
import { Center } from "./Utils/Center";


export class BasketTable extends TableBuilder<DynamicBasketItem>
{
    constructor(dynamicBasket: DynamicBasket)
    {
        super(dynamicBasket.Items);

        this.WidthAuto().MarginAuto();

        this.AddColumn("Usługa", p => new Label(p.Label))
            .AddColumn("Produkt", p => new ProductView(p.Product))
            .AddColumn("Ilość", p => [
                new PlusMinusInput(p.Quantity).MarginBottom(0),
                new Label(p.DiscountLabel).FontSize(14).MarginTop(0)
            ])
            .AddColumn("Koszt", p => new Label(p.Cost, v => v + " zł").Bold().DisplayInlineBlock().Width(70))
            .AddColumn("", (x, row) =>
            {
                row.TextAlignLeft();
                return [
                    new Link("❌ Usuń").NoDecorationOnHover().OnClick(() => dynamicBasket.Remove(x)),
                ];
            })
            .ForEachRow(row => row.Background("#fafafa").MarginLeftRight(12))
            .WhenEmpty(new Center("Brak produktów").MarginTopBottom(32))
            .Build();
    }
}
