import { Div, Image, Span } from "@tblabs/truffle";
import { ProductCategory } from "../../../Models/Product/ProductCategory";


export class ProductCategoryBox extends Div
{
    constructor(category: ProductCategory, priceFrom: string)
    {
        super();
        this.Style('display: inline-block; position: relative; cursor: pointer; border: 1px solid #ccc; margin: 8px; width: 340px; x-height: 272px; background: #ddd; background: radial-gradient(circle, rgba(230,230,230,1) 0%, rgba(208,208,208,1) 100%); border: 12px solid #fff');

        this.OnClick(() => window.location.hash = "category/" + category.Url.value);

        this.Append(
            new Image(category.Image.value).Style("width: 48%; height: 200px; width: 340px; border-bottom: 1px solid orange"),
            new Div().Text(category.Title.value).Style("margin: 8px").FontSize(22).Bold().Color("#333"),
            new Div().Text(category.Subtitle.value).FontSize(15).Italic().Style("margin: 4px 8px 12px"),
            new Span(priceFrom).Italic().Padding(8).Style('position: absolute; top: 10px; right: 10px; opacity: 0.8; font-size: 14px; color: #222; background: #ccc; padding: 4px 8px; border-radius: 8px')
        )
    }
}
