import { RefString } from "@tblabs/truffle";
import { RawMarkdownField } from "./RawMarkdownField";
import { Field } from "../Field";
import { FieldType } from "../FieldType";


export class MarkdownField extends Field
{
    public Type = FieldType.Markdown;

    public Markdown = new RefString();

    constructor(raw: Partial<RawMarkdownField>)
    {
        super(raw);

        this.Markdown.value = raw.Markdown || "";
    }
}
