import { Collection, Link, Ref, RefBool, Snack } from "@tblabs/truffle";
import { WebsiteContentProvider } from "../Content/WebsiteContentProvider";
import { BasketLocalStorage } from "./LocalBasket";
import { BasketItemType } from "./BasketItemType";
import { Product } from "../../Models/Product/Product";
import { RentBasketItem } from "../../Models/Basket/RentBasketItem";
import { SaleBasketItem } from "../../Models/Basket/SaleBasketItem";
import { BasketItem } from "../../Models/Basket/BasketItem";


export class Basket
{
    constructor(private _storage: BasketLocalStorage, public Content: WebsiteContentProvider)
    { }

    public Clear()
    {
        this._storage.Clear()
    }

    public Rent(product: Product): void
    {
        if (this._storage.Items.Items.find(x => x.ProductId.value == product.Id && x.Type == BasketItemType.Rent))
        {
            new Snack(`Produkt jest już w koszyku`);
            return;
        }
        const basketItem = new RentBasketItem({ ProductId: product.Id, Quantity: 1, From: new Date(), To: new Date() });
        this._storage.Add(basketItem);
        this.ShowAddedSnack();
    }

    public Buy(product: Product): void
    {
        if (this._storage.Items.Items.find(x => x.ProductId.value == product.Id && x.Type == BasketItemType.Sale))
        {
            new Snack(`Produkt jest już w koszyku`);
            return;
        }
        const basketItem = new SaleBasketItem({ ProductId: product.Id, Quantity: 1 });
        this._storage.Add(basketItem);
        this.ShowAddedSnack();
    }

    public get IsAnythingToRent(): boolean
    {
        return this.Products.Items.some(x => x.Type == BasketItemType.Rent)
    }

    public get IsAnythingToRentRef(): Ref<boolean>
    {
        const isAnythingToRent = new RefBool()
        this.Products.OnChange(items => isAnythingToRent.value = items.some(x => x.Type == BasketItemType.Rent), true)
        return isAnythingToRent;
    }

    public Remove(item: BasketItem): void
    {
        this._storage.Remove(item)
    }

    public get Products(): Collection<BasketItem>
    {
        return this._storage.Items;
    }

    private ShowAddedSnack()
    {
        new Snack("🛒 Dodano do koszyka!", new Link("Otwórz koszyk")
            .OnClick(() => window.location.hash = "basket"))
    }
}
