import { Ref, RefString, Radio } from "@tblabs/truffle";
import { ReturnForm } from "../../Models/Basket/ReturnForm";
import { InputRow } from "../Utils/InputRow";

export class ReturnFormSelector extends InputRow
{
    constructor(returnForm: Ref<ReturnForm>)
    {
        super("Forma",
            new Radio(returnForm).Class("radio")
                .Add(ReturnForm.Package, "Przesyłka Inpost (na koszt nadawcy)")
                .Add(ReturnForm.Personal, "Zwrot osobisty (bez kosztów)")
                .Add(ReturnForm.Taxi, "Taxi (na koszt nadawcy; tylko w Warszawie)")
                .Add(ReturnForm.Rentomat, "Rentomat (bez kosztów)", x => x.Disabled())
        );
    }
}
