import { GalleryField } from "../../Models/Description/Gallery/GalleryField";
import { TextField } from "../../Models/Description/Text/TextField";
import { Field } from "../../Models/Description/Field";
import { FieldType } from "../../Models/Description/FieldType";
import { RawFaqField } from "../../Models/Description/Faq/RawFaqField";
import { RawGalleryField } from "../../Models/Description/Gallery/RawGalleryField";
import { RawMarkdownField } from "../../Models/Description/Markdown/RawMarkdownField";
import { RawField } from "../../Models/Description/RawField";
import { RawTextField } from "../../Models/Description/Text/RawTextField";
import { FaqField } from "../../Models/Description/Faq/FaqField";
import { MarkdownField } from "../../Models/Description/Markdown/MarkdownField";
import { TableField } from "../../Components/Description/Table/TableField";
import { RawTableField } from "../../Models/Description/Table/RawTableField";
import { RawOpinionField } from "../../Models/Description/Opinion/RawOpinionField";
import { OpinionField } from "../../Models/Description/Opinion/OpinionField";


export class DescriptionFieldConverter
{
    public static ToClass(raw: RawField): Field
    {
        switch (raw.Type as FieldType)
        {
            case FieldType.Markdown: return new MarkdownField(raw as RawMarkdownField);
            case FieldType.Faq: return new FaqField(raw as RawFaqField);
            case FieldType.Gallery: return new GalleryField(raw as RawGalleryField);
            case FieldType.Table: return new TableField(raw as RawTableField);
            case FieldType.Text: return new TextField(raw as RawTextField);
            case FieldType.Opinion: return new OpinionField(raw as RawOpinionField);
            default: throw new Error(`[DescriptionFieldConverter] Unknown "${raw.Type}" description field type`)
        }
    }
}
