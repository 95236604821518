import { Div } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";


export class Box extends Div
{
    constructor(...cmps: appendable[])
    {
        super();

        this.Append(
            ...cmps
        )
    }
}
