import { RefString, Div, Link, TextInput, Line, Ref, RefDate, Label, DestroyingContentSwitcher, RefBool, JsonViewer, RefSerializer } from "@tblabs/truffle";
import { Center } from "../Utils/Center";
import moment from "moment";
import { DatesRange } from "../../Models/Dates/DatesRange";
import { DatesRangeInput } from "../Inputs/DatesRangeInput";
import { DeliveryForm, DeliveryFormValueToLabel } from "../../Models/Basket/DeliveryForm";
import { ReturnForm, ReturnFormValueToLabel } from "../../Models/Basket/ReturnForm";
import { InputRow } from "../Utils/InputRow";
import { Section } from "../Utils/Section";
import { Basket } from "../../Services/Basket/Basket";
import { WebsiteContent } from "../../Models/WebsiteContent/WebsiteContent";
import { HashLink } from "../Utils/HashLink";
import { PaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { Loader } from "../Utils/Loader";
import { v4 as uuidv4 } from 'uuid';
import { DangerButton } from "../Utils/DangerButton";
import { PrimaryButton } from "../Utils/PrimaryButton";
import { Ticketer } from "../../Services/Repo/TicketSender";
import { SummaryBasketItem } from "../../Models/Basket/SummaryBasketItem";
import { BasketSummary } from "../../Models/Basket/BasketSummary";
import { DynamicBasket } from "../../Services/Basket/DynamicBasket";
import { RefWatcher } from "../../Utils/RefWatcher";
import { Cost } from "../../Models/Basket/Cost";
import { ReturnableCost } from "../../Models/Basket/ReturnableCost";
import { Discount } from "../../Models/Basket/Discount";
import { Box } from "../Box";
import { BasketTable } from "../BasketTable";
import { DeliveryFormSelector } from "./DeliveryFormSelector";
import { PaymentFormSelector } from "./PaymentFormSelector";
import { OrderFormView } from "./OrderFormView";
import { CustomerInfo } from "./CustomerInfo";
import { CustomerInput } from "./CustomerInput";
import { ReturnFormSelector } from "./ReturnFormSelector";
import { DepositReturnInfo } from "./DepositReturnInfo";
import { CostsSummaryTable } from "./CostsSummaryTable";


export class OrderForm extends Div
{
    constructor(content: WebsiteContent, private basket: Basket, private _ticketer: Ticketer)
    {
        super();

        //#region vars
        const customer = new CustomerInfo()
        const deliveryForm = new Ref<DeliveryForm>(DeliveryForm.Unset).Storable("delivery-form")
        const returnForm = new Ref<ReturnForm>(ReturnForm.Unset).Storable("return-form")
        const paymentForm = new Ref<PaymentForm>(PaymentForm.Unset).Storable("payment-form")
        const start = new RefDate(moment(new Date()).hour(12).minute(0).toDate()).Storable("rent-from")
        const end = new RefDate(moment(new Date()).hour(12).minute(0).toDate()).Storable("rent-to")
        const rentDatesRange = new DatesRange(start, end)
        const dynamicBasket = new DynamicBasket(content, basket, rentDatesRange)
        const summary = new BasketSummary()
        const view = new Ref<OrderFormView>(OrderFormView.Form)
        const depositInfo = new RefString()
        const depositReturnInfo = new RefString()
        const trainingInfo = new RefString()
        const allowCashPayment = new RefBool()
        const showCustomerDepositAccountInput = new RefBool()
        const place = new RefString("Warszawa/Wola");
        //#endregion

        // 🧠
        RefWatcher.Watch([deliveryForm, paymentForm, returnForm, dynamicBasket.Items], () =>
        {
            const personalDelivery = [DeliveryForm.PersonalWithTraining, DeliveryForm.PersonalWithoutTraining].includes(deliveryForm.value);
            const personalReturn = [ReturnForm.Personal].includes(returnForm.value);

            // 🎓 Training
            trainingInfo.value = {
                [DeliveryForm.Pocztex]: `Szkolenie odbędzie się przez telefon. Otrzymasz także materiały do samodzielnej nauki`,
                [DeliveryForm.PersonalWithTraining]: `Szkolenie odbędzie się na miejscu. Otrzymasz także materiały do samodzielnej nauki`,
                [DeliveryForm.PersonalWithoutTraining]: `Szkolenie nie odbędzie się na miejscu. Zamiast tego otrzymasz materiały do samodzielnej nauki oraz dostęp do darmowej infolinii`,
            }[deliveryForm.value] || "...";

            // Package + Cash is not possible, so switch to the next available option
            if (deliveryForm.Is(DeliveryForm.Pocztex) && paymentForm.Is(PaymentForm.Cash))
                paymentForm.value = PaymentForm.DepositPrepaidServicePostpaid;
            // Nothing to rent + Personal delivery + Cash at delivery is not possible, so switch to the next available option
            if (!basket.IsAnythingToRent && !deliveryForm.Is(DeliveryForm.Pocztex) && paymentForm.Is(PaymentForm.CashAtDelivery))
                paymentForm.value = PaymentForm.Cash;

            // 💳 Payment
            allowCashPayment.value = personalDelivery;

            // 💰 Deposit
            depositInfo.value = {
                [PaymentForm.Cash]: `Kaucja zostanie pobrana gotówką podczas odbioru sprzętu`,
                [PaymentForm.DepositPrepaidServicePostpaid]: `Łączna suma kaucji wynosi ${dynamicBasket.GetDepositsSum()}zł. Płatne z góry przelewem`,
                [PaymentForm.PartialDepositPrepaidServicePostpaid]: `Łączna suma kaucji wynosi ${dynamicBasket.GetDepositsSum()}zł, z czego 60zł jest płatne z góry przelewem, pozostała kwota kaucji zostanie doliczona do kwoty pobrania`,
                [PaymentForm.FromPreviousOrder]: `Wykorzystamy kaucję z Twojego poprzedniego zamówienia o ile to możliwe (wartość kaucji jest taka sama i poprzednie zamówienie nie zostało jeszcze rozliczone)`,
                [PaymentForm.Crypto]: `Kaucja zostanie wliczona w koszt przelewu`,
                [PaymentForm.Transfer]: `Kwota kaucji zostanie doliczona do kwoty przelewu`
            }[paymentForm.value] || "...";

            // 💰 Deposit return
            showCustomerDepositAccountInput.value = personalDelivery && !personalReturn && paymentForm.Is(PaymentForm.Cash);

            depositReturnInfo.value = {
                [PaymentForm.Cash]: `Kaucja zostanie zwrócona na miejscu w gotówce`,
                [PaymentForm.Transfer]: `Kaucja zostanie zwrócona na konto, z którego przyszła`,
                [PaymentForm.DepositPrepaidServicePostpaid]: `Kaucja zostanie odesłana na konto, z którego przyszła`,
                [PaymentForm.PartialDepositPrepaidServicePostpaid]: `Kaucja zostanie zwrócona w dwóch przelewach`,
                [PaymentForm.Crypto]: `Kaucja zostanie zwrócona na wskazany portfel (wyliczona z proporcji)`,
                [PaymentForm.FromPreviousOrder]: `Jako że kaucja została opłacona w poprzednim zamówieniu - zostanie zwrócona w sposób zgodny z formą jej opłacenia`,
            }[paymentForm.value] || "...";
        });

        // 📺
        this.Append(
            new DestroyingContentSwitcher(view)
                .AddContent(OrderFormView.Form, () => new Box(
                    new Center(
                        new PrimaryButton("Przejdź do podsumowania")
                            .EnableWhen([basket.Products.CountRef, deliveryForm, paymentForm],
                                (count, delivery, payment) =>
                                    +count > 0
                                    && delivery != DeliveryForm.Unset
                                    && payment != PaymentForm.Unset)
                            .OnClick(async () =>
                            {
                                summary.Clear()

                                summary.OrderId = uuidv4()

                                summary.SetCustomer(customer)

                                summary.DeliveryForm = deliveryForm.value;
                                summary.ReturnForm = returnForm.value;
                                summary.PaymentForm = paymentForm.value;

                                dynamicBasket.Items.ForEach(x =>
                                {
                                    const item = new SummaryBasketItem()
                                    item.Cost = x.Cost.value;
                                    item.Product = x.Product;
                                    item.Type = x.Type;
                                    item.Quantity = x.Quantity.value;
                                    item.RentStart = x.RentStart;
                                    item.RentEnd = x.RentEnd;
                                    summary.BasketItems.push(item)

                                    summary.Add(new Cost(`${x.Label.value}: ${x.Product.Name} (×${x.Quantity.value})`, x.Cost.value))
                                })

                                let depositsSum = dynamicBasket.GetDepositsSum();
                                if (depositsSum)
                                {
                                    summary.Add(new ReturnableCost("Kaucja zwrotna", depositsSum))
                                }

                                deliveryForm.Is(DeliveryForm.PersonalWithTraining)
                                    ? summary.Add(new Cost("Szkolenie z obsługi", 50))
                                    : summary.Add(new Cost("Dostawa", 1))

                                if (paymentForm.Is(PaymentForm.Crypto))
                                {
                                    const cryptoDiscount = summary.NonReturnableCostsSum * 0.1;
                                    summary.Add(new Discount("Zniżka za płatność w Bitcoin", cryptoDiscount))
                                }

                                view.value = OrderFormView.Summary;
                            }),
                    ).Margin(32),
                    new Section("🗓️ Okres wypożyczenia", [
                        new DatesRangeInput(rentDatesRange)
                    ]).Visible(this.basket.IsAnythingToRentRef, "block"),
                    new Section("🛒 Twoje zamówienie", [
                        new BasketTable(dynamicBasket),
                        new Center(
                            new HashLink("➕ Dodaj produkty").Color("#1e87f0")
                        ).MarginTop(20),
                    ]),
                    new Section("🚚 Odbiór / dostawa", [
                        new DeliveryFormSelector(deliveryForm, place),
                        new Line(),
                        new PaymentFormSelector(paymentForm, allowCashPayment, this.basket.IsAnythingToRentRef),
                        new Line().Visible(this.basket.IsAnythingToRentRef),
                        new InputRow("💰 Kaucja", new Label(depositInfo))
                            .Visible(basket.IsAnythingToRentRef, "flex"),
                        new Line(),
                        new InputRow("🎓 Szkolenie", new Label(trainingInfo)),
                    ]),
                    new Section("📦 Zwrot", [
                        new ReturnFormSelector(returnForm),
                        new Line(),
                        new DepositReturnInfo(depositReturnInfo, showCustomerDepositAccountInput),
                    ]).Visible(this.basket.IsAnythingToRentRef, "block"),
                    new Section("🤵 Twoje dane", [
                        new CustomerInput(customer),
                    ]),
                    new Section("🎈 Rabaty", [
                        new InputRow("Kod rabatowy", new TextInput().Placeholder("Wpisz jeśli jakiś posiadasz")),
                    ]),
                    new Line(),
                ))
                .AddContent(OrderFormView.Summary, () =>
                {
                    const returnToEditBtn = new PrimaryButton("Wróć do edycji").OnClick(() => view.value = OrderFormView.Form).MarginLeft(8)

                    const loader = new Loader("Wysyłanie...").DisplayInline().Hide()

                    return new Div().Append(
                        new Center(
                            new DangerButton("Wyślij zgłoszenie").OnClick(async (btn) =>
                            {
                                btn.Disabled()
                                returnToEditBtn.Hide()
                                loader.Show()

                                try
                                {
                                    const result = await this._ticketer.Send(summary);

                                    view.value = result.IsSuccess ? OrderFormView.Sent : OrderFormView.SendingFault;
                                }
                                catch (error)
                                {
                                    console.log(error)
                                    view.value = OrderFormView.SendingFault;
                                }
                                finally
                                {
                                    // console.log('finally')
                                    // clearTimeout(timeout);
                                }

                                returnToEditBtn.Hide()
                                loader.Hide()
                            }),
                            loader,
                            returnToEditBtn,
                        ).Margin(32),
                        new Section("🧮 Podsumowanie kosztów", [
                            new CostsSummaryTable(summary, basket, paymentForm),
                        ]),
                        basket.IsAnythingToRent && new Section("🚚 Dostawa i zwrot", [
                            new InputRow("Dostawa", DeliveryFormValueToLabel[deliveryForm.value], ", ", summary.RentStartAsString),
                            basket.IsAnythingToRent && new InputRow("Zwrot", ReturnFormValueToLabel[returnForm.value], ", ", summary.RentEndAsString),
                        ]),
                        !basket.IsAnythingToRent && new Section("🚚 Dostawa", [
                            new InputRow("Dostawa", DeliveryFormValueToLabel[deliveryForm.value]),
                        ]),
                        basket.IsAnythingToRent && new Section("💰 Kaucja", [
                            new InputRow("Wpłata", depositInfo.value),
                            new InputRow("Zwrot", depositReturnInfo.value),
                        ]),
                        new Section("👦 Twoje dane", [
                            new InputRow("Imię i nazwisko", customer.Name.value),
                            new InputRow("Kontakt", customer.Phone.value, ", ", customer.Email.value),
                            new InputRow("Adres", customer.Address.value),
                        ]),
                        new Line(),
                    )
                })
                .AddContent(OrderFormView.Sent, () =>
                {
                    setTimeout(() =>
                    {
                        const ordersSystemWebsite = process.env.ORDERS_WEB?.replace("{orderId}", summary.OrderId) || "";
                        basket.Clear();
                        // window.location.href = ordersSystemWebsite;
                    }, 3 * 1000)
                    this.Append(new JsonViewer(RefSerializer.Flatenize(summary)))

                    return new Center().Margin(32).Append(
                        "Zgłoszenie zostało wysłane. Za chwilę nastąpi przekierowanie na stronę zamówienia...")
                })
                .AddContent(OrderFormView.SendingFault, () =>
                {
                    return new Center().Margin(32).Append(
                        "Nie udało się wysłać zgłoszenia. Spróbuj ponownie za chwile.\n\n",
                        new Link("Wyślij ponownie").OnClick(() => view.value = OrderFormView.Summary)
                    )
                }),
        )
    }
}
