import { OrderForm } from "../../Components/OrderForm/OrderForm";
import { Basket } from "../../Services/Basket/Basket";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { Ticketer } from "../../Services/Repo/TicketSender";
import { WebsitePage } from "../Components/WebsitePage";


export class BasketPage extends WebsitePage
{
    constructor(_website: WebsiteContentProvider, _basket: Basket, _ticketer: Ticketer)
    {
        super(_website);

        this.WhenReady((websiteContent) =>
        {
            return new OrderForm(websiteContent, _basket, _ticketer)
        })
    }
}
