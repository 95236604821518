import { Div, Ref, Span } from "@tblabs/truffle";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { BasketSummary } from "../../Models/Basket/BasketSummary";
import { Discount } from "../../Models/Basket/Discount";
import { PaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { Basket } from "../../Services/Basket/Basket";
import { Markdown } from "../Utils/Markdown";



export class CostsSummaryTable extends Div
{
    constructor(summary: BasketSummary, basket: Basket, paymentForm: Ref<PaymentForm>)
    {
        super();

        this.Append(

            new Table([],
                [
                    ...summary.Costs.map(x =>
                    {
                        if (x instanceof Discount)
                            return ([x.Label, new Span("– " + x.Value.toFixed(2) + " zł").Color("blue")]);

                        else
                            return ([x.Label, x.Value.toFixed(2) + " zł"]);
                    }),
                ]
            ).Class("uk-table").WidthPercent(100).WidthAuto().MarginAuto().TextAlignLeft(),

            new Markdown(`Łącznie do wpłaty: **${summary.Sum.toFixed(2)} zł**`)
                .DisplayBlock().Margin(0).MarginTop(24).MarginLeft(16),
            basket.IsAnythingToRent && new Markdown(`Do zwrotu po oddaniu sprzętu: **${summary.ToReturn.toFixed(2)} zł**`)
                .DisplayBlock().Margin(0).MarginLeft(16),
            paymentForm.Is(PaymentForm.Crypto) && new Markdown(`Złotówki zostaną przeliczone na Bitcoiny dopiero w kolejnym kroku.`).MarginLeft(32).Italic()
        );
    }
}
