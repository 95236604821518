import { Collection, MultilineInput } from "@tblabs/truffle";
import { MarkdownField } from "../../../Models/Description/Markdown/MarkdownField";
import { Field } from "../../../Models/Description/Field";
import { FieldEditor } from "../../Utils/FieldEditor";
import { Markdown } from "../../Utils/Markdown";


export class MarkdownEditor extends FieldEditor
{
    constructor(description: MarkdownField, descriptions: Collection<Field>)
    {
        super("Markdown", description, descriptions, [
            new MultilineInput(description.Markdown).Rows(8).Placeholder("Markdown").Class("markdown-edit"),
            new Markdown(description.Markdown).Class("markdown-edit-preview"),
        ]);
    }
}
