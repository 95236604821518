"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rate = exports.RateConfig = exports.RateStar = void 0;
var Div_1 = require("../Containers/Div");
var Span_1 = require("../Containers/Span");
var RateStar = /** @class */ (function (_super) {
    __extends(RateStar, _super);
    function RateStar(rating, showWhenLowerOrEqual, config) {
        var _this = _super.call(this, "span") || this;
        _this.showWhenLowerOrEqual = showWhenLowerOrEqual;
        _this.config = config;
        _this.selected = new Span_1.Span(_this.config.SelectedSymbol)
            .Style(_this.config.SelectedStyle)
            .Hide();
        _this.deselected = new Span_1.Span(_this.config.DeselectedSymbol)
            .Style(_this.config.DeselectedStyle);
        _this.CursorPointer().DisplayInlineBlock();
        _this.Append(_this.selected, _this.deselected);
        rating.OnChange(function (v) {
            if (v >= _this.showWhenLowerOrEqual) {
                _this.selected.Show();
                _this.deselected.Hide();
            }
            else {
                _this.selected.Hide();
                _this.deselected.Show();
            }
        }, true);
        return _this;
    }
    return RateStar;
}(Div_1.Div));
exports.RateStar = RateStar;
var RateConfig = /** @class */ (function () {
    function RateConfig() {
        this.Count = 5;
        this.SelectedSymbol = "◼";
        this.SelectedStyle = "";
        this.DeselectedSymbol = "◻";
        this.DeselectedStyle = "";
        this.Readonly = false;
    }
    return RateConfig;
}());
exports.RateConfig = RateConfig;
var Rate = /** @class */ (function (_super) {
    __extends(Rate, _super);
    function Rate(rating, config) {
        var _this = _super.call(this) || this;
        _this.Config = new RateConfig();
        if (config)
            _this.Config = __assign(__assign({}, _this.Config), config);
        var _loop_1 = function (i) {
            var star = new RateStar(rating, i, this_1.Config);
            if (this_1.Config.Readonly == false) {
                star.OnClick(function () { return rating.value = i; });
            }
            this_1.Append(star);
        };
        var this_1 = this;
        for (var i = 1; i <= _this.Config.Count; i++) {
            _loop_1(i);
        }
        return _this;
    }
    Rate.Name = "Rate";
    return Rate;
}(Div_1.Div));
exports.Rate = Rate;
