import { Div, RefBool, Link } from "@tblabs/truffle";
import { FaqField } from "../../../Models/Description/Faq/FaqField";
import { FaqEntryView } from "./FaqPrinter";
import { Center } from "../../Utils/Center";


export class FaqsPrinter extends Div
{
    constructor(field: FaqField)
    {
        super();
        this.TextAlignLeft();

        const expand = new RefBool();

        this.Append(
            ...field.Questions
                .Take(0, 3)
                .map(x => new FaqEntryView(x)),

            (field.Questions.Count > 3) && new Center(
                new Link(`Pokaż wszystkie ${field.Questions.Count}...`).Margin(32)
                    .OnClick(_ => { _.Hide(); expand.True(); })),

            new Div().Append(
                ...field.Questions
                    .Take(3, field.Questions.Count)
                    .map(x => new FaqEntryView(x)))
                .Visible(expand)
        )
    }
}
