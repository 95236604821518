import { Div, Label } from "@tblabs/truffle";
import { Header } from "../Utils/Header";
import { Markdown } from "../Utils/Markdown";
import { TextField } from "../../Models/Description/Text/TextField";
import { MarkdownField } from "../../Models/Description/Markdown/MarkdownField";
import { FaqsPrinter } from "./Faq/FaqsPrinter";
import { Gallery } from "./Gallery/Gallery";
import { FaqField } from "../../Models/Description/Faq/FaqField";
import { GalleryField } from "../../Models/Description/Gallery/GalleryField";
import { TableField } from "./Table/TableField";
import { Field } from "../../Models/Description/Field";
import { FieldType } from "../../Models/Description/FieldType";
import { OpinionsPrinter } from "./Opinion/OpinionsPrinter";
import { appendable } from "@tblabs/truffle/Core/appendable";
import { Center } from "../Utils/Center";
import { TablePrinter } from "./Table/TablePrinter";
import { OpinionField } from "../../Models/Description/Opinion/OpinionField";


export class FieldPrinter extends Div
{
    constructor(field: Field)
    {
        super();
        this.TextAlignLeft()

        if (field.VisibleHeader.value)
        {
            this.Append(
                new Header(field.Header.value)
            )
        }

        let printer: appendable;

        switch (field.Type)
        {
            case FieldType.Text:
                printer = new Label((field as TextField).Text)
                break;
            case FieldType.Markdown:
                printer = new Markdown((field as MarkdownField).Markdown.value).MarginLeftRight(8)
                break;
            case FieldType.Faq:
                printer = new FaqsPrinter(field as FaqField)
                break;
            case FieldType.Opinion:
                printer = new OpinionsPrinter(field as OpinionField)
                break;
            case FieldType.Gallery:
                printer = new Gallery((field as GalleryField).Images).Background("none").MarginAuto()
                break;
            case FieldType.Table:
                printer = new TablePrinter(field as TableField)
                break;
            default:
                printer = new Center(`No printer for ${field.Type}`);
                break;
        }

        printer.Style(field.Style.value)

        this.Append(printer)
    }
}
