import { Collection, CollectionPrinter, Div, Link } from "@tblabs/truffle";
import { Field } from "../../../Models/Description/Field";
import { FieldEditor } from "../../Utils/FieldEditor";
import { GalleryField } from "../../../Models/Description/Gallery/GalleryField";
import { GalleryEditEntryView } from "./GalleryItemEditor";
import { GalleryItem } from "../../../Models/Description/Gallery/GalleryItem";


export class GalleryItemsEditor extends FieldEditor
{
    constructor(field: GalleryField, fields: Collection<Field>)
    {
        super("Galeria", field, fields, [
            new CollectionPrinter(field.Images, x => new GalleryEditEntryView(x, field.Images)),
            new Div().ClearBoth(),
            new Link("➕ Obraz").OnClick(() => field.Images.Add(new GalleryItem({ Url: "", Description: "" }))),
        ]);
    }
}
