import { Ref, Label, TextInput } from "@tblabs/truffle";
import { InputRow } from "../Utils/InputRow";


export class DepositReturnInfo extends InputRow
{
    constructor(depositReturnInfo: Ref<string>, showCustomerDepositAccountInput: Ref<boolean>)
    {
        super("Zwrot kaucji",
            new Label(depositReturnInfo)
                .VisibleWhen(showCustomerDepositAccountInput, v => !v),
            new TextInput().Placeholder("Wpisz swój numer konta do zwrotu kaucji")
                .Visible(showCustomerDepositAccountInput)
        );
    }
}
