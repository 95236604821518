
export enum PaymentType
{
    Unset = "Unset",
    Deposit = "Deposit",
    DepositReturn = "DepositReturn",
    Service = "Service",
    Rent = "Rent",
    Sale = "Sale",
    Basket = "Basket",
    ServiceSurcharge = "ServiceSurcharge",
    Cost = "Cost",
    Repair = "Repair",
    Transport = "Transport",
    Return = "Return",
    MentorProvision = "MentorProvision",
    AgentProvision = "AgentProvision",
    RecommenderProvision = "RecommenderProvision",
    RepairmanProvision = "RepairmanProvision",
    Bonus = "Bonus",
    MentorBonus = "MentorBonus",
    Discount = "Discount",
    ExtraCost = "ExtraCost"
}
