import { Div, RefNumber, Button, Label } from "@tblabs/truffle";


export class PlusMinusInput extends Div
{
    constructor(ref: RefNumber)
    {
        super();
        this.Width(80).TextAlignCenter()

        const minusBtn = new Button("➖").Padding(0).CursorPointer()
            .OnClick(() =>
            {
                if (ref.value > 1)
                    ref.value -= 1;
            });
        const plusBtn = new Button("➕").Padding(0).CursorPointer()
            .OnClick(() => ref.value += 1);
        const display = new Label(ref).Margin(4).Bold().FontSize(16);

        this.Append(
            minusBtn,
            display,
            plusBtn
        )
    }
}
