import { RefString } from "@tblabs/truffle";
import { Field } from "../Field";
import { FieldType } from "../FieldType";
import { RawTextField } from "./RawTextField";


export class TextField extends Field
{
    public Type = FieldType.Text;
    public Text = new RefString();

    constructor(raw: Partial<RawTextField>)
    {
        super(raw);
        
        this.Text.value = raw.Text || "";
    }
}
