import { RefBool, RefString } from "@tblabs/truffle";
import { FieldType } from "./FieldType";
import { RawField } from "./RawField";


export abstract class Field
{
    public abstract Type: FieldType;
    public Header = new RefString();
    public Style = new RefString();
    public VisibleHeader = new RefBool();

    constructor(raw: Partial<RawField>)
    {
        this.Header.value = raw.Header || "";
        this.VisibleHeader.value = raw.VisibleHeader ?? true;
        this.Style.value = raw.Style || "";
    }
}
