import moment from "moment";


export class DispatchTimeCalculator
{
    public static Calc(deliverAt: Date, threshold = 4): Date
    {
        let whenSend = moment(deliverAt);

        while (threshold > 0)
        {
            const dayOfWeek = whenSend.day();

            // Is weekend
            if ([6, 0].includes(dayOfWeek))
            {
                whenSend.subtract(1, 'day');
            }

            whenSend.subtract(1, 'day');

            threshold -= 1;
        }

        return whenSend.toDate();
    }
}
