import { Div } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";


export class Left extends Div
{
    constructor(...components: appendable[])
    {
        super();
        this.TextAlignLeft();

        this.Append(
            ...components);
    }
}
