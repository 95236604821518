import { Div, Image } from "@tblabs/truffle";
import { Row } from "../../Components/Utils/Row";


export class PageLogo extends Div
{
    constructor()
    {
        super();

        this.Append(
            new Row(
                new Image("https://www.specteam.pl/img/logo_black.png") // TODO: zmienić na photos.specteam.pl
                    .FloatLeft()
                    .CursorPointer()
                    .OnClick(() => window.location.hash = "/"),
                new Div().DisplayInlineBlock().Html("&nbsp;&nbsp;WYPOŻYCZALNIA<br>&nbsp;SPRZĘTU<br>DETEKTYWISTYCZNEGO")
                    .OnClick(() => window.location.hash = "/")
                    .FloatLeft().CursorPointer().Color("#ff8642")
                    .MarginTop(20).MarginLeft(16).TextAlignLeft()
                    .Bold()
                    .Italic()
                    .FontSize(19),
            )
        )
    }
}
