import { Collection } from "@tblabs/truffle";
import { RawGalleryField } from "./RawGalleryField";
import { Field } from "../Field";
import { FieldType } from "../FieldType";
import { GalleryItem } from "./GalleryItem";


export class GalleryField extends Field
{
    public Type = FieldType.Gallery;
    public Images = new Collection<GalleryItem>();

    constructor(raw: Partial<RawGalleryField>)
    {
        super(raw);
        
        this.Images.Load(...raw.Images?.map(x => new GalleryItem(x)) || []);
    }
}
