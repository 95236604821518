import { appendable } from "@tblabs/truffle/Core/appendable";
import { Left } from "../Utils/Left";


export class AddFieldBox extends Left
{
    constructor(...cmps: appendable[])
    {
        super(...cmps);
        this.Class("add-field-box");

        this.ClearBoth().MarginTop(8);
    }
}
