import { RefDate } from "@tblabs/truffle";
import { BasketItemType } from "../../Services/Basket/BasketItemType";
import { BasketItem } from "./BasketItem";
import { RawRentBasketItem } from "./RawRentBasketItem";


export class RentBasketItem extends BasketItem
{
    public From = new RefDate();
    public To = new RefDate();

    constructor(raw: Partial<RawRentBasketItem>)
    {
        super(raw.ProductId, raw.Quantity);

        this.Type = BasketItemType.Rent;
        this.From.value = raw.From || new Date(0);
        this.To.value = raw.To || new Date(0);
    }

    public get TypeAsString(): string
    {
        return "Wypożyczenie";
    }
}
