import { Div, Collection } from "@tblabs/truffle";
import { Field } from "../../Models/Description/Field";
import { FieldPrinter } from "./FieldPrinter";


export class DescriptionViewer extends Div
{
    constructor(collection: Collection<Field>)
    {
        super();

        this.Append(
            ...collection.Items.map(entry => new FieldPrinter(entry))
        )
    }
}
