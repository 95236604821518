import { Div, RefBool } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";
import { Header } from "./Header";
import { Row } from "./Row";


export class Section extends Div
{
    constructor(label: string, body: appendable[])
    {
        super();

        this.Append(
            new Header(label),
            new Row(...body),
        )
    }
}

export class FoldableSection extends Div
{
    constructor(label: string, body: appendable[])
    {
        super();

        const bodyVisible = new RefBool().Storable(label)

        this.Append(
            new Header(label).CursorPointer().OnClick(() => bodyVisible.Toggle()),
            new Row(...body).Visible(bodyVisible),
        )
    }
}
