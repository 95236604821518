import { DeliveryForm } from "./DeliveryForm";
import { ReturnForm } from "./ReturnForm";
import { Cost } from "./Cost";
import { Customer } from "./Customer";
import { SummaryBasketItem } from "./SummaryBasketItem";
import moment from "moment";
import { PaymentForm } from "../OrderTicket/PaymentForm";
import { BasketItemType } from "./BasketItemType";
import { Discount } from "./Discount";
import { ReturnableCost } from "./ReturnableCost";
import { CustomerInfo } from "../../Components/OrderForm/CustomerInfo";


export class BasketSummary
{
    public OrderId: string = "";
    public Costs: (Cost | ReturnableCost | Discount)[] = [];
    public BasketItems: SummaryBasketItem[] = [];
    public Customer = new Customer();
    public PaymentForm: PaymentForm = PaymentForm.Unset;
    public DeliveryForm: DeliveryForm = DeliveryForm.Unset;
    public ReturnForm: ReturnForm = ReturnForm.Unset;
    
    public SetCustomer(customer: CustomerInfo)
    {
        this.Customer.Name = customer.Name.value;
        this.Customer.Phone = customer.Phone.value;
        this.Customer.Email = customer.Email.value;
        this.Customer.Address = customer.Address.value;
        this.Customer.Experience = customer.Experience.value;
    }

    public get IsAnythingToRent(): boolean
    {
        return this.BasketItems.some(x => x.Type == BasketItemType.Rent)
    }
    public get RentStart(): Date 
    {
        if (!this.IsAnythingToRent)
            throw new Error(`Nothing to rent`)

        return this.BasketItems.find(x => x.Type == BasketItemType.Rent)?.RentStart || new Date(0);
    }
    public get RentStartAsString(): string
    {
        return moment(this.RentStart).format("dddd DD.MM.YYYY");
    }
    public get RentEnd(): Date 
    {
        if (!this.IsAnythingToRent)
            throw new Error(`Nothing to rent`)

        return this.BasketItems.find(x => x.Type == BasketItemType.Rent)?.RentEnd || new Date(0);
    }
    public get RentEndAsString(): string
    {
        return moment(this.RentEnd).format("dddd DD.MM.YYYY");
    }
    public Clear()
    {
        this.Costs = [];
    }

    public get Sum(): number
    {
        return this.Costs.filter(x => x instanceof Cost || x instanceof ReturnableCost).reduce((p, c) => p + c.Value, 0);
    }

    public get ToReturn(): number
    {
        return this.Costs.filter(x => x instanceof ReturnableCost).reduce((p, c) => p + c.Value, 0);
    }
    public Add(x: Cost | ReturnableCost | Discount, product?: SummaryBasketItem): void
    {
        this.Costs.push(x);

        if (product)
            this.BasketItems.push(product);
    }
    public get NonReturnableCosts(): Cost[]
    {
        return this.Costs.filter(x => x instanceof Cost);
    }
    public get ReturnableCosts(): Cost[]
    {
        return this.Costs.filter(x => x instanceof ReturnableCost);
    }
    public get NonReturnableCostsSum(): number
    {
        return this.NonReturnableCosts.reduce((p, c) => p + c.Value, 0);
    }
    public get ReturnableCostsSum(): number
    {
        return this.ReturnableCosts.reduce((p, c) => p + c.Value, 0);
    }
}
