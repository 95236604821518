import { Collection, MultilineInput } from "@tblabs/truffle";
import { TextField } from "../../../Models/Description/Text/TextField";
import { FieldEditor } from "../../Utils/FieldEditor";
import { Field } from "../../../Models/Description/Field";


export class TextEditor extends FieldEditor
{
    constructor(description: TextField, descriptions: Collection<Field>)
    {
        super("Text", description, descriptions, [
            new MultilineInput(description.Text).Rows(8).TextAlignLeft().Padding(12).Placeholder("Czysty tekst"),
        ]);
    }
}
