import { RefDate, RefString } from "@tblabs/truffle";
import { RawBlogArticle } from "./RawBlogArticle";


export class BlogArticle
{
    public Title = new RefString();
    public Url = new RefString();
    public Text = new RefString();
    public PublishDate = new RefDate();
    public Author = new RefString();

    constructor(raw: Partial<RawBlogArticle>)
    {
        this.Title.value = raw.Title || "";
        this.Url.value = raw.Url || "";
        this.Text.value = raw.Text || "";
        this.PublishDate.value = new Date(raw.PublishDate || 0);
        this.Author.value = raw.Author || "";
    }
}
