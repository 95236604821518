
export enum OrderBasketItemStatus
{
    Unset = "Unset",
    Checking = "Checking",
    AwaitingConfirmation = "AwaitingConfirmation",
    InitiallyConfirmed = "InitiallyConfirmed",
    Confirmed = "Confirmed",
    Unavailable = "Unavailable",
    Rejected = "Rejected",
    InUse = "InUse",
    Malfunction = "Malfunction",
    Returned = "Returned",
    NotReturned = "NotReturned"
}
