import moment from "moment";
import { BasketSummary } from "../Models/Basket/BasketSummary";
import { HumanType } from "../Models/OrderTicket/HumanType";
import { OrderBasketItemStatus } from "../Models/OrderTicket/OrderBasketItemStatus";
import { TrainingStatus } from "../Models/OrderTicket/TrainingStatus";
import { TrainingType } from "../Models/OrderTicket/TrainingType";
import { TransitDirection } from "../Models/OrderTicket/TransitDirection";
import { TransitStatus } from "../Models/OrderTicket/TransitStatus";
import { TransitType } from "../Models/OrderTicket/TransitType";
import { DeliveryForm } from "../Models/Basket/DeliveryForm";
import { ReturnForm } from "../Models/Basket/ReturnForm";
import { BasketItemType } from "./Basket/BasketItemType";
import { PaymentForm } from "../Models/OrderTicket/PaymentForm";
import { RawPackageTransit, RawPersonalTransit, RawTaxiTransit } from "../Models/OrderTicket/RawTransit";
import { OrderTicket } from "./Basket/OrderTicket";
import { RawRentTicketBasketItem, RawTicketBasketItem } from "../Models/OrderTicket/RawTicketBasketItem";
import { PaymentType } from "../Models/OrderTicket/PaymentType";
import { DispatchTimeCalculator } from "./DispatchTimeCalculator";
import { PaymentStatus } from "../Models/OrderTicket/PaymentStatus";

export class TicketCreator
{
    public CreateTicket(summary: BasketSummary): OrderTicket
    {
        const ticket = new OrderTicket(summary.OrderId);

        // 🧔 People
        ticket.People
            .Clear()
            .Add({
                Type: HumanType.Customer,
                Name: summary.Customer.Name,
                Phone: summary.Customer.Phone,
                Address: summary.Customer.Address,
                Email: summary.Customer.Email,
                Experience: summary.Customer.Experience
            })

        // 🛒 Basket
        ticket.Basket.Clear()
        summary.BasketItems.forEach(x =>
        {
            let item: Partial<RawTicketBasketItem> = {
                Type: x.Type,
                Product: x.Product,
                TotalPrice: x.Cost,
                Quantity: x.Quantity,
                Status: OrderBasketItemStatus.AwaitingConfirmation,
            }

            switch (x.Type)
            {
                case BasketItemType.Rent:
                    item = { ...item, Timeline: { Start: x.RentStart, End: x.RentEnd } } as Partial<RawRentTicketBasketItem>;
                    break;
            }

            ticket.Basket.Add(item)
        })

        // 💰 Payment
        let depositPaymentForm = PaymentForm.Unset;
        let depositReturnPaymentForm = PaymentForm.Unset;
        let basketPaymentForm = PaymentForm.Unset;
        let currency = "";

        switch (summary.PaymentForm)
        {
            case PaymentForm.Cash:
                depositPaymentForm = PaymentForm.Cash;
                depositReturnPaymentForm = summary.ReturnForm == ReturnForm.Personal ? PaymentForm.Cash : PaymentForm.Transfer;
                basketPaymentForm = PaymentForm.Cash;
                currency = "PLN";
                break;
            case PaymentForm.DepositPrepaidServicePostpaid:
                depositPaymentForm = PaymentForm.Transfer;
                depositReturnPaymentForm = PaymentForm.CashAtDelivery;
                basketPaymentForm = PaymentForm.Transfer;
                currency = "PLN";
                break;
            case PaymentForm.Crypto:
                depositPaymentForm = PaymentForm.Crypto;
                depositReturnPaymentForm = PaymentForm.Crypto;
                basketPaymentForm = PaymentForm.Crypto;
                currency = "BTC";
                break;
            default:
                throw new Error(`Unhandled payment form`);
        }

        let basketPaymentDeadline = new Date(0);
        if (summary.DeliveryForm == DeliveryForm.Pocztex)
        {
            basketPaymentDeadline = moment(summary.RentEnd).add(14, 'days').toDate()
        }

        ticket.Payment
            .Clear()
            .Add({ Type: PaymentType.Basket, PaymentDeadline: basketPaymentDeadline, Amount: summary.NonReturnableCostsSum, Currency: currency, Form: basketPaymentForm, VisibleForCustomer: true, Status: PaymentStatus.Awaiting })

        if (summary.IsAnythingToRent)
        {
            let depositReturnPaymentDeadline = moment(summary.RentEnd).add(7, 'days').toDate()
            
            ticket.Payment
                .Add({ Type: PaymentType.Deposit, PaymentDeadline: summary.RentStart, Amount: summary.ReturnableCostsSum, Currency: currency, Form: depositPaymentForm, VisibleForCustomer: true, Status: PaymentStatus.Awaiting })
                .Add({ Type: PaymentType.DepositReturn, PaymentDeadline: depositReturnPaymentDeadline, Amount: summary.ReturnableCostsSum, Currency: currency, Form: depositReturnPaymentForm, VisibleForCustomer: true, Status: PaymentStatus.Quequed })
        }

        // 🚚 Transit  
        ticket.Transit.Clear()

        switch (summary.DeliveryForm)
        {
            case DeliveryForm.Pocztex:
            case DeliveryForm.Inpost:
                ticket.Transit.Add({
                    Type: TransitType.Package,
                    Direction: TransitDirection.ToCustomer,
                    PlannedDispatchTime: summary.IsAnythingToRent ? DispatchTimeCalculator.Calc(summary.RentStart) : new Date(),
                    Status: TransitStatus.Preparing,
                } as RawPackageTransit)
                break;
            case DeliveryForm.PersonalWithTraining:
            case DeliveryForm.PersonalWithoutTraining:

                ticket.Transit.Add({
                    Type: TransitType.Personal,
                    Direction: TransitDirection.ToCustomer,
                    PlannedMeetingTime: summary.IsAnythingToRent ? moment(summary.RentStart).subtract(1, 'day').toDate() : new Date(),
                    Status: TransitStatus.Preparing,
                } as RawPersonalTransit)
                break;
            default:
                throw new Error(`Unhandled delivery form`);
        }

        switch (summary.ReturnForm)
        {
            case ReturnForm.Package:
                ticket.Transit.Add({
                    Type: TransitType.Package,
                    Direction: TransitDirection.FromCustomer,
                    Status: TransitStatus.Planned,
                    PlannedDispatchTime: summary.IsAnythingToRent ? summary.RentEnd : new Date(0),
                } as RawPackageTransit)
                break;
            case ReturnForm.Personal:
                ticket.Transit.Add({
                    Type: TransitType.Personal,
                    Direction: TransitDirection.FromCustomer,
                    Status: TransitStatus.Planned,
                    PlannedMeetingTime: summary.IsAnythingToRent ? summary.RentEnd : new Date(0),
                } as RawPersonalTransit)
                break;
            case ReturnForm.Taxi:
                ticket.Transit.Add({
                    Type: TransitType.Taxi,
                    Direction: TransitDirection.FromCustomer,
                    Status: TransitStatus.Planned,
                    When: summary.IsAnythingToRent ? summary.RentEnd : new Date(0),
                } as RawTaxiTransit)
                break;
            default:
                throw new Error(`Unhandled return form`);
        }

        // 🎓 Training
        ticket.Training.Clear()
        if (summary.DeliveryForm == DeliveryForm.PersonalWithTraining)
        {
            ticket.Training.Add({
                Type: TrainingType.Personal, Status: TrainingStatus.Undone,
                Label: "Szkolenie odbędzie się podczas odbioru sprzętu"
            })
        }
        ticket.Training.Add({
            Type: TrainingType.ViaTelephone, Status: TrainingStatus.Available,
            Label: "Pomoc techniczna ☎️ 507-293-714"
        })

        summary.BasketItems.forEach(x =>
        {
            const label = `Materiały szkoleniowe dla ${x.Product.Name}`;
            if (!ticket.Training.Items.find(x => x.Label == label))
                ticket.Training.Add({ Type: TrainingType.Self, Status: TrainingStatus.AvailableSoon, Label: label, Link: x.Product.Tutorial || "" })
        })

        return ticket;
    }
}