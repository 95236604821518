import { WebsiteContent } from "../../Models/WebsiteContent/WebsiteContent";
import { RawWebsiteContent } from "../../Models/WebsiteContent/RawWebsiteContent";
import { RawWebsiteContentRepo } from "../Repo/RawWebsiteContentRepo";
import { WebsiteContentConverter } from "./WebsiteContentConverter";

export class ContentUpdateStatus
{
    public IsSuccess = false;
    public ContentSize = -1;
    public ErrorMessage = "";
}
function Delay(ms: number): Promise<void>
{
    return new Promise(resolve => setTimeout(resolve, ms));
}

// TODO: local storage
// TODO: copy saver
export class WebsiteContentProvider
{
    private cache: RawWebsiteContent;

    constructor(
        private _repo: RawWebsiteContentRepo,
        private _converter: WebsiteContentConverter)
    { }

    public async Update(content: WebsiteContent): Promise<ContentUpdateStatus>
    {
        const raw = this._converter.ToRaw(content);

        const result = await this._repo.Update(raw);

        if (result.IsSuccess)
        {
            const rawContent = await this._repo.Get();

            if (rawContent)
                this.cache = rawContent;
            else throw new Error(`Could not get content`)
        }

        const updateStatus = new ContentUpdateStatus();
        updateStatus.IsSuccess = result.IsSuccess;
        updateStatus.ContentSize = JSON.stringify(raw).length / 1024;
        updateStatus.ErrorMessage = result.ErrorMessage

        return updateStatus;
    }

    private hardcoded: RawWebsiteContent = {
       
    } as RawWebsiteContent;

    public async Get(): Promise<WebsiteContent>
    {
        // await Delay(500)
        const raw = this.cache || await this._repo.Get() || this.hardcoded;
        // console.log(raw)
        this.cache = raw;
        const content = this._converter.FromRaw(raw);

        // console.log(content)
        if (!content)
            throw new Error(`Could not get website content`);

        return content;
    }
}
