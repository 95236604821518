import { Div, RefBool, Collection, Span, Link, TextInput, Checkbox } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";
import { Field } from "../../Models/Description/Field";


export class FieldEditor extends Div
{
    constructor(private type: string, private item: Field, collection: Collection<Field>, content: appendable[] = [])
    {
        super();
        this.Background("#f8f8f8")
            .Border(4, "#333")
            .BorderRadius(8)
            .Padding(0)
            .MarginBottom(12)

        let expand = new RefBool(true).Storable(`${this.type}:${this.item.Header.value}`);

        this.Append(
            new Div().Padding(4).Background("#333").Color("#eee").Append(
                new Span(type).Background("#eee").Color("#222").Padding(2   ).BorderRadius(8).FloatLeft(),
                new Link("👀Zwiń/rozwiń").MarginLeft(12).FloatLeft()
                    .OnClick(() => expand.Toggle()),
                new TextInput(item.Header).Placeholder("Tytuł sekcji").WidthAuto().MarginLeft(12).FloatLeft(),
                new Checkbox(item.VisibleHeader, "Widoczny tytuł").MarginLeft(12).FloatLeft(),
                new TextInput(item.Style).Placeholder("Styl elementu (CSS)").MarginLeftRight(8).FontSize(12).FloatLeft(),
                new Link("✖️").OnClick(() => collection.Remove(item)).FloatRight(),
                collection.CanSwapUp(item) && new Link("⬆️").FloatRight()
                    .OnClick(() => collection.SwapUp(item)),
                collection.CanSwapDown(item) && new Link("⬇️").FloatRight()
                    .OnClick(() => collection.SwapDown(item)),
                new Div().ClearBoth(),
            ),
            new Div().WidthPercent(100).Append(
                ...content
            ).Visible(expand),
            new Div().ClearBoth(),
        )
    }
}
