import { Collection } from "@tblabs/truffle";
import { Field } from "../Field";
import { FieldType } from "../FieldType";
import { RawFaqField } from "./RawFaqField";
import { Faq } from "./Faq";


export class FaqField extends Field
{
    public Type = FieldType.Faq;
    public Questions = new Collection<Faq>();

    constructor(raw: Partial<RawFaqField>)
    {
        super(raw);

        this.Questions.Load(...raw.Questions?.map(x => new Faq(x)) || []);
    }
}
