import { Collection } from "@tblabs/truffle";
import { RawPayment } from "../../Models/OrderTicket/RawPayment";
import { RawHuman } from "../../Models/OrderTicket/RawHuman";
import { RawTransit } from "../../Models/OrderTicket/RawTransit";
import { RawTraining } from "../../Models/OrderTicket/RawTraining";
import { OrderMeta } from "./OrderMeta";
import { RawTicketBasketItem } from "../../Models/OrderTicket/RawTicketBasketItem";


export class OrderTicket
{
    public Payment = new Collection<Partial<RawPayment>>();
    public Basket = new Collection<Partial<RawTicketBasketItem>>();
    public People = new Collection<Partial<RawHuman>>();
    public Transit = new Collection<Partial<RawTransit>>();
    public Training = new Collection<Partial<RawTraining>>();
    public Meta = new OrderMeta();
    
    constructor(public Id: string)
    { 
        this.Meta.Created = new Date();
        this.Meta.CreatedBy = window.location.hostname;
    }
}
