import { Collection, TextInput, MultilineInput, Rate } from "@tblabs/truffle";
import { Opinion } from "../../../Models/Description/Opinion/Opinion";
import { EntryEdit } from "../../Utils/EntryEdit";


export class OpinionEdit extends EntryEdit<Opinion>
{
    constructor(opinion: Opinion, opinions: Collection<Opinion>)
    {
        super(opinion, opinions);

        this.Append(
            new TextInput(opinion.Author).Placeholder("Autor").WidthPercent(40).FloatLeft(),
            new Rate(opinion.Rate, { SelectedSymbol: "⭐", DeselectedSymbol: "⭐", DeselectedStyle: "opacity: 0.4" }).FloatLeft(),
            new MultilineInput(opinion.Comment).Rows(4).Placeholder("Komentarz"),
            new TextInput(opinion.Adventages).Placeholder("Zalety"),
            new TextInput(opinion.Disadventages).Placeholder("Wady"),
        )
    }
}
