import { Div, RefString, TextInput, Select, NumberInput, MultilineInput, Line, Button, CollectionPrinter, Link, TableBuilder, Snack, Checkbox, Span } from "@tblabs/truffle";
import { Row } from "../../Components/Utils/Row";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { AuthService } from "../../Services/Auth/AuthService";
import { InputRow } from "../../Components/Utils/InputRow";
// import { GalleryEditEntryView } from "../../Components/Gallery/GalleryEditEntryView";
import { FoldableSection } from "../../Components/Utils/Section";
import { DescriptionEditor } from "../../Components/Description/DescriptionEditor";
import { v4 as uuidv4 } from 'uuid';
import { Loader } from "../../Components/Utils/Loader";
import { Product } from "../../Models/Product/Product";
import { RentPrice } from "../../Models/Product/RentPrice";
import { GalleryItem } from "../../Models/Description/Gallery/GalleryItem";
import { GalleryEditEntryView } from "../../Components/Description/Gallery/GalleryItemEditor";


export class ProductEditPage extends Div
{
    private loader = new Loader()
    private content = new Div()

    constructor(private _content: WebsiteContentProvider, private _auth: AuthService, private productUrl?: string)
    {
        super();

        this.Append(
            this.loader,
            this.content,
        )
    }

    protected async OnAppend(): Promise<void>
    {
        if (!this._auth.IsAuthorized)
        {
            this.Append(`Brak autoryzacji`);
            return;
        }

        const content = await this._content.Get();

        const product = this.productUrl
            ? content.ProductByUrl(this.productUrl)
            : new Product({ Id: uuidv4() });

        this.loader.RemoveSelf()
        this.content.Append(
            new Row(
                new FoldableSection("Meta", [
                    new InputRow("Id", new Span(product.Id || "(puste pole id)")),
                    new InputRow("Kategoria produktu", new Select(product.CategoryId, content.ProductCategoriesAsTitleIdObject)),
                    new InputRow("Tytuł", new TextInput(product.Title).Placeholder("Nazwa widoczna")),
                    new InputRow("Podpis", new TextInput(product.Subtitle).Placeholder("Podpis pod nazwą")),
                    new InputRow("Url", new TextInput(product.Url).Placeholder("Nazwa w url (bez spacji i polskich znaków!)")),
                    new InputRow("Nazwa w koszyku", new TextInput(product.BasketName).Placeholder("Nazwa w koszyku klienta")),
                    new InputRow("Tagi (oddzielone spacją)", new TextInput(product.Tags).Placeholder("Lista tagów po spacji")),
                    new InputRow("Ficzery (od nowej linii)", new MultilineInput(product.Features).Rows(8)),
                    new InputRow("Link do instrukcji", new TextInput(product.TutorialLink).Placeholder("Link do tutoriala")),
                ]),
                new FoldableSection("Cennik", [
                    new InputRow("Cena wynajmu",
                        new TableBuilder(product.RentPrices)
                            .AddColumn("Cena brutto", x => new NumberInput(x.Value))
                            .AddColumn("Za ile dni", x => new NumberInput(x.Days))
                            .AddColumn("Czy widoczne", x => new Checkbox(x.Visible))
                            .AddColumn("", x => new Link("❌").OnClick(() => product.RentPrices.Remove(x)))
                            .Build(),
                        new Link("➕ Dodaj").OnClick(() => product.RentPrices.Add(new RentPrice({ Value: 1, Days: 1 }))),
                    ),
                    new InputRow("Cena zakupu", new NumberInput(product.SalePrice)),
                    new InputRow("Kaucja", new NumberInput(product.Deposit)),
                ]),
                new FoldableSection("Galeria", [
                    new CollectionPrinter(product.Images, x => new GalleryEditEntryView(x, product.Images)),
                    new Div().ClearBoth(),
                    new Link("➕ Zdjęcie").OnClick(() => product.Images.Add(new GalleryItem({}))),
                ]),
                new FoldableSection("Opis", [
                    new DescriptionEditor(product.Description),
                ]),
                new Line(),
                new Button("Zapisz").Class("selected")
                    .OnClick(async () =>
                    {
                        if (!this.productUrl) // is new
                        {
                            content.Products.Add(product)
                        }
                        const result = await this._content.Update(content);
                        new Snack(result.IsSuccess ? "Zmiany zapisane!" : "❌ Problem z zapisaniem zmian", result.ErrorMessage || (result.ContentSize.toFixed(2) + "kb"))
                        window.location.hash = `product/${product.Url.value}`;
                    }),
                (this.productUrl) && new Button("❌ Usuń").MarginLeft(8)
                    .OnClick(async () =>
                    {
                        content.Products.Remove(product);
                        const result = await this._content.Update(content);
                        new Snack(result.IsSuccess ? "Zmiany zapisane!" : "❌ Problem z zapisaniem zmian", result.ErrorMessage || (result.ContentSize.toFixed(2) + "kb"))
                        const category = content.ProductCategories.Items.find(x => x.Id.value == product.CategoryId.value)
                        window.location.hash = `category/${category?.Url?.value}`;
                    }),
            )
        )
    }
}
