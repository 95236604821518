import { Div } from "@tblabs/truffle";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { Markdown } from "../../Utils/Markdown";
import { TableField } from "./TableField";


export class TablePrinter extends Div
{
    constructor(field: TableField)
    {
        super();

        const tableRows = field.Rows.Items.map(x => [new Markdown(x.Left.value), new Markdown(x.Right.value)]);

        this.Append(
            new Table([], tableRows).Background("none").Class("uk-table").WidthAuto().MarginAuto()
        );
    }
}
