import { Collection } from "@tblabs/truffle";
import { Field } from "../Field";
import { FieldType } from "../FieldType";
import { Opinion } from "./Opinion";
import { RawOpinionField } from "./RawOpinionField";


export class OpinionField extends Field
{
    public Type = FieldType.Opinion;
    public Opinions = new Collection<Opinion>();

    constructor(raw: Partial<RawOpinionField>)
    {
        super(raw);

        this.Opinions.Load(...raw.Opinions?.map(x => new Opinion(x)) || []);
    }
}
