import { Ref } from "@tblabs/truffle";


export class CustomerInfo
{
    public Name = new Ref<string>("").Storable("customer-name");
    public Address = new Ref<string>("").Storable("customer-phone");
    public Email = new Ref<string>("").Storable("customer-email");
    public Phone = new Ref<string>("").Storable("customer-address");
    public Experience = new Ref<number>(0).Storable("customer-exp");
}
