import { Collection, RefSerializer } from "@tblabs/truffle";
// import { CustomerBasketItem, RawStoredBasketItem } from "../../Models/CustomerBasketItem";
import { BasketItemType } from "./BasketItemType";
import { BasketItem } from "../../Models/Basket/BasketItem";
import { RawBasketItem } from "../../Models/Basket/RawBasketItem";
import { RentBasketItem } from "../../Models/Basket/RentBasketItem";
import { RawRentBasketItem } from "../../Models/Basket/RawRentBasketItem";
import { SaleBasketItem } from "../../Models/Basket/SaleBasketItem";
import { RawSaleBasketItem } from "../../Models/Basket/RawSaleBasketItem";


export class BasketLocalStorage
{
    public Items = new Collection<BasketItem>();

    public Remove(item: BasketItem)
    {
        this.Items.Remove(item)
        this.Save();
    }

    public Clear()
    {
        this.Items.RemoveAll();
        this.Save();
    }

    constructor()
    {
        const storedItems = window.localStorage.getItem("basket");

        if (!storedItems)
            return;

        const deserialized: RawBasketItem[] = JSON.parse(storedItems);

        const basketItems = deserialized
            .map(x =>
            {
                switch (x.Type)
                {
                    case BasketItemType.Rent:
                        return new RentBasketItem(x as RawRentBasketItem);
                    case BasketItemType.Sale:
                        return new SaleBasketItem(x as RawSaleBasketItem);
                    default:
                        throw new Error(`Unknown basket item type "${x.Type}"`)
                }
            })

        this.Items.Load(...basketItems);
    }

    public Save()
    {
        const serialized = RefSerializer.Serialize(this.Items.Items);
        // console.log(serialized)

        window.localStorage.setItem("basket", serialized);
    }

    public Add(item: BasketItem)
    {
        this.Items.Add(item);
        this.Save();
    }
}
