import { Collection } from "@tblabs/truffle";
import { FieldType } from "../../../Models/Description/FieldType";
import { TableItem } from "../../../Models/Description/Table/TableItem";
import { Field } from "../../../Models/Description/Field";
import { RawTableField } from "../../../Models/Description/Table/RawTableField";


export class TableField extends Field
{
    public Type = FieldType.Table;
    public Rows = new Collection<TableItem>();

    constructor(raw: Partial<RawTableField>)
    {
        super(raw);

        this.Rows.Load(...raw.Rows?.map(x => new TableItem(x)) || []);
    }
}
