import { Div, Link } from "@tblabs/truffle";
import { HashLink } from "../../Components/Utils/HashLink";
import { Center } from "../../Components/Utils/Center";


export class PageFooter extends Div
{
    constructor()
    {
        super("page-footer");

        this.Append(
            new Center(
                new Div("centered").TextAlignRight().Append(
                    new HashLink("🐓 Zaloguj", "admin/login"),
                    new HashLink("📰 Blog", "blog"),
                    new Link("⛽ Praca", "https://recruit.specteam.pl/#jobs").Class("hash-link"),
                ),
            ),
        )
    }
}
