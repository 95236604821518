import { RefSerializer, Collection, Ref } from "@tblabs/truffle";
import { DescriptionFieldConverter } from "./DescriptionFieldConverter";
import { BlogArticle } from "../../Models/Blog/BlogArticle";
import { RawBlogArticle } from "../../Models/Blog/RawBlogArticle";
import { RawField } from "../../Models/Description/RawField";
import { Product } from "../../Models/Product/Product";
import { ProductCategory } from "../../Models/Product/ProductCategory";
import { RawProduct } from "../../Models/Product/RawProduct";
import { RawProductCategory } from "../../Models/Product/RawProductCategory";
import { RawWebsiteContent } from "../../Models/WebsiteContent/RawWebsiteContent";
import { WebsiteContent } from "../../Models/WebsiteContent/WebsiteContent";


export class WebsiteContentConverter
{
    public ToRaw(content: WebsiteContent): RawWebsiteContent
    {
        return RefSerializer.Flatenize(content) as RawWebsiteContent;
    }

    public FromRaw(raw: RawWebsiteContent): WebsiteContent | null
    {
        try
        {
            const content = new WebsiteContent();

            this.SetList(content.AboutUs, raw.AboutUs, (raw: RawField) => DescriptionFieldConverter.ToClass(raw));
            this.SetList(content.ProductCategories, raw.ProductCategories, (x: RawProductCategory) => new ProductCategory(x));
            this.SetList(content.Products, raw.Products, (x: RawProduct) => new Product(x));
            this.SetList(content.Contact, raw.Contact, (raw: RawField) => DescriptionFieldConverter.ToClass(raw));
            this.SetList(content.Blog, raw.Blog, (x: RawBlogArticle) => new BlogArticle(x));

            return content;
        }
        catch (ex: any)
        {
            console.error(`Website content conversion from raw problem: ${ex.message}`);

            return null;
        }
    }

    private GetList<R, T>(value: R[], creator: (raw: R) => T): Collection<T>
    {
        const items = value?.map(x => creator(x));

        return new Collection<T>(items);
    }

    private SetList<T, R>(target: Collection<T>, source: R[], converter: (x: R) => T): void
    {
        const list = this.GetList<R, T>(source, converter);
        target.Load(...list.Items);
    }

    private GetString(value: any): string
    {
        return value || "";
    }

    private SetString(ref: Ref<string>, raw: string): void
    {
        ref.value = this.GetString(raw);
    }

    private GetNumber(value: any): number
    {
        return +value || 0;
    }

    private GetBool(value: any): boolean
    {
        return !!value || false;
    }

    private GetDate(date: any): Date
    {
        return date ? new Date(date) : new Date(0);
    }
}
