import { Div, RefDate, RefNumber } from "@tblabs/truffle";
import moment from "moment";


export class DatesRange extends Div
{
    public DaysCount = new RefNumber()

    constructor(public Start: RefDate, public End: RefDate)
    {
        super();

        Start.value = moment(Start.value).hour(12).minute(0).toDate();
        End.value = moment(End.value).hour(12).minute(0).toDate();
        Start.OnChange(v =>
        {
            this.DaysCount.value = moment(End.value).diff(v, 'days') + 1;
            if (this.DaysCount.value <= 0)
                End.value = Start.value;
        })
        End.OnChange(v =>
        {
            this.DaysCount.value = moment(v).diff(Start.value, 'days') + 1;
            if (this.DaysCount.value <= 0)
                Start.value = End.value;
        }, true)
    }

    public AddDay()
    {
        this.End.value = moment(this.End.value).add(1, 'day').toDate();
    }

    public SubtractDay()
    {
        this.End.value = moment(this.End.value).subtract(1, 'day').toDate();
    }
}
