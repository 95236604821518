import { Label, Div } from "@tblabs/truffle";
import { Header } from "../../Components/Utils/Header";
import { Markdown } from "../../Components/Utils/Markdown";
import { Row } from "../../Components/Utils/Row";
import { AuthService } from "../../Services/Auth/AuthService";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { Loader } from "../../Components/Utils/Loader";
import { PageLogo } from "../Components/PageTop";
import { AdminEditButton } from "../../Components/Admin/AdminEditButton";
import { Page } from "../Components/Page";


export class BlogPage extends Page
{
    private loader = new Loader()
    private content = new Row()

    constructor(private _content: WebsiteContentProvider, private _user: AuthService, private url?: string)
    {
        super();

        this.body.Append(
            new PageLogo(),
            this.loader,
            this.content
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();

        this.loader.RemoveSelf();

        if (this.url)
        {
            const article = content.Blog.Items.find(x => x.Url.value == this.url);

            if (!article)
            {
                this.Append("Nie znaleziono wpisu");
                return;
            }

            this.content.Insert(
                new Header(
                    "Blog > ", new Label(article?.Title).Style('font-weight: 600').Color("#fe8015"),
                    this._user.IsAuthorized && new AdminEditButton(`edit/page`),
                ),
                new Row(
                    new Markdown(article.Text)
                )
            )
        }

        this.content.Insert(
            ...content.Blog.Items.map(x => new Div().Append(
                new Header(
                    "Blog > ", new Label(x.Title).Style('font-weight: 600').Color("#fe8015"),
                    this._user.IsAuthorized && new AdminEditButton(`edit/page`),
                ),
                new Row(
                    new Markdown(x.Text)
                )
            ))
        )
    }
}
