import { RefString, RefNumber } from "@tblabs/truffle";
import { RawProductInfo } from "../../Models/OrderTicket/RawProductInfo";


export class DynamicBasketItem
{
    public Type = "";
    public Product: Partial<RawProductInfo> = {};
    
    public Label = new RefString();
    public Deposit = 0;

    public Quantity = new RefNumber();
    public DiscountLabel = new RefString();
    public Cost = new RefNumber();
    
    // For Rent type
    public RentStart: Date;
    public RentEnd: Date;
}
