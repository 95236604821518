import { Collection, CollectionPrinter, Div, RefString } from "@tblabs/truffle";
import { AdminEditButton } from "../../Components/Admin/AdminEditButton";
import { Features } from "../Components/Features";
import { PageLogo } from "../Components/PageTop";
import { Header } from "../../Components/Utils/Header";
import { Loader } from "../../Components/Utils/Loader";
import { Row } from "../../Components/Utils/Row";
import { AuthService } from "../../Services/Auth/AuthService";
import { WebsiteContentProvider } from "../../Services/Content/WebsiteContentProvider";
import { ProductCategoryBox } from "../Category/Components/ProductCategoryBox";
import { Page } from "../Components/Page";
import { Center } from "../../Components/Utils/Center";
import { Product } from "../../Models/Product/Product";
import { ProductBox } from "../Product/Components/ProductBox";
import { ClearableTextInput } from "../../Components/Inputs/ClearableTextInput";
import { Box } from "../../Components/Box";
import { DescriptionViewer } from "../../Components/Description/DescriptionViewer";


export class MainPage extends Page
{
    private loader1 = new Loader()
    private content = new Row()

    constructor(private _content: WebsiteContentProvider, private _auth: AuthService)
    {
        super();

        this.body.Append(
            new PageLogo(),
            new Features(),
            new Header("Nasza oferta", _auth.IsAuthorized && new AdminEditButton('edit/category')),
            this.loader1,
            this.content,
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();
        const categories = content.ProductCategories.Items;

        this.loader1.RemoveSelf()

        const searchResult = new Collection<Product>()
        // let throttleTimer;
        const search = new RefString()
            .OnChange(s =>
            {
                // clearTimeout(throttleTimer)
                // setTimeout(() =>
                // {
                    const products = content.Products.Filter(x =>
                    {
                        return x.Tags.value.includes(s)
                    });

                    searchResult.Load(...products)
                // }, 1333)
            })

        this.content.Insert(
            new Center(
                new ClearableTextInput(search, "🔎 Szukaj produktu..."),
            ).MarginBottom(16),

            new Box(
                "Wyniki wyszukiwania: ",
                new CollectionPrinter(searchResult, p => new ProductBox(p)))
                .VisibleWhen(search, x => x.length > 0),

            new Box(
                "Nic nie znaleziono",
            ).VisibleWhen(search, x => x.length > 0 && searchResult.Count == 0),

            new Div().Append(
                ...categories.map(x =>
                {
                    let priceFor = "⚠️ brak produktów";
                    const firstProduct = content.ProductsByCategory(x)?.[0];
                    if (firstProduct)
                    {
                        const rent = firstProduct.RentPrices.Items[0];
                        const price = Math.round(rent.Value.value / rent.Days.value);
                        priceFor = `już od ${price}zł / dzień`;
                    }
                    return new ProductCategoryBox(x, priceFor)
                }),
                new Div().Clear(),

                this._auth.IsAuthorized && new AdminEditButton('edit/page'),
                new DescriptionViewer(content.AboutUs),
                
            ).VisibleWhen(search, x => x.length == 0)
        )
    }
}