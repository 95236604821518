import { Div, Collection, CollectionPrinter, Span, Link } from "@tblabs/truffle";
import { TextField } from "../../Models/Description/Text/TextField";
import { TextEditor } from "./Text/TextEditor";
import { Field } from "../../Models/Description/Field";
import { FieldType } from "../../Models/Description/FieldType";
import { FaqsEditor } from "./Faq/FaqsEditor";
import { GalleryField } from "../../Models/Description/Gallery/GalleryField";
import { MarkdownEditor } from "./Markdown/MarkdownEditor";
import { TableItemsEditor } from "./Table/TableItemsEditor";
import { GalleryItemsEditor } from "./Gallery/GalleryItemsEditor";
import { MarkdownField } from "../../Models/Description/Markdown/MarkdownField";
import { FaqField } from "../../Models/Description/Faq/FaqField";
import { TableField } from "./Table/TableField";
import { OpinionsEditor } from "./Opinion/OpinionsEditor";
import { OpinionField } from "../../Models/Description/Opinion/OpinionField";
import { AddFieldBox } from "../Admin/AddFieldBox";


export class DescriptionEditor extends Div
{
    constructor(collection: Collection<Field>)
    {
        super();

        this.Append(
            new CollectionPrinter(collection, x =>
            {
                switch (x.Type as FieldType)
                {
                    case FieldType.Markdown: return new MarkdownEditor(x as MarkdownField, collection);
                    case FieldType.Faq: return new FaqsEditor(x as FaqField, collection);
                    case FieldType.Text: return new TextEditor(x as TextField, collection);
                    case FieldType.Gallery: return new GalleryItemsEditor(x as GalleryField, collection);
                    case FieldType.Table: return new TableItemsEditor(x as TableField, collection);
                    case FieldType.Opinion: return new OpinionsEditor(x as OpinionField, collection);
                    default: return new Span(`Unknown description field type "${x.Type}"`);
                }
            }),
            new AddFieldBox(
                new Link("➕ Markdown").OnClick(() => collection.Add(new MarkdownField({ Header: "Markdown" }))),
                new Link("➕ Tekst").OnClick(() => collection.Add(new TextField({ Header: "Tekst" }))),
                new Link("➕ Faq").OnClick(() => collection.Add(new FaqField({ Header: "Pytania i odpowiedzi" }))),
                new Link("➕ Galeria").OnClick(() => collection.Add(new GalleryField({ Header: "Galeria" }))),
                new Link("➕ Tabela").OnClick(() => collection.Add(new TableField({ Header: "Tabela" }))),
                new Link("➕ Opinie").OnClick(() => collection.Add(new OpinionField({ Header: "Opinie" }))),
            ),
        )
    }
}
