import { Ref, Radio } from "@tblabs/truffle";
import { PaymentForm } from "../../Models/OrderTicket/PaymentForm";
import { InputRow } from "../Utils/InputRow";


export class PaymentFormSelector extends InputRow
{
    constructor(paymentForm: Ref<PaymentForm>, allowCashPayment: Ref<boolean>, isAnythingToRent: Ref<boolean>)
    {
        super("Płatność",
            new Radio(paymentForm).Class("radio")
                .Add(PaymentForm.Cash, "Gotówka (cała opłata przy odbiorze)", x => x.Enable(allowCashPayment))
                .Add(PaymentForm.DepositPrepaidServicePostpaid, "Całościowa przedpłata kaucji przelewem, reszta przy odbiorze")
                .Add(PaymentForm.PartialDepositPrepaidServicePostpaid, "Częściowa przedpłata kaucji przelewem, reszta przy odbiorze", x => x.Disabled())
                .Add(PaymentForm.FromPreviousOrder, "Kaucja z poprzedniego zamówienia, płatność za usługę przy odbiorze", x => x.Disabled())
                .Add(PaymentForm.Crypto, "Bitcoin (całość z góry, 10% rabatu)")
                .Add(PaymentForm.Transfer, "Przelew (całość z góry)", x => x.Disabled())
                .Visible(isAnythingToRent, "flex"),
            new Radio(paymentForm).Class("radio")
                .Add(PaymentForm.Cash, "Gotówka", x => x.Enable(allowCashPayment))
                .Add(PaymentForm.CashAtDelivery, "Gotówka przy odbiorze, płatne u kuriera", x => x.Disable(allowCashPayment))
                .Add(PaymentForm.Crypto, "Bitcoin (całość z góry, 10% rabatu)")
                .Add(PaymentForm.Transfer, "Przelew (całość z góry)", x => x.Disabled())
                .VisibleWhen(isAnythingToRent, v => !v, "flex")
        );
    }
}
