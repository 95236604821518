import { Collection, TextInput, MultilineInput, DateInput } from "@tblabs/truffle";
import { EntryEdit } from "../../Components/Utils/EntryEdit";
import { BlogArticle } from "./BlogArticle";


export class BlogEntry extends EntryEdit<BlogArticle>
{
    constructor(entry: BlogArticle, entries: Collection<BlogArticle>)
    {
        super(entry, entries);

        this.LeftSide.Append(
            new TextInput(entry.Title).Placeholder("Tytuł"),
            new TextInput(entry.Url).Placeholder("Url"),
            new TextInput(entry.Author).Placeholder("Autor").WidthPercent(50),
            new DateInput(entry.PublishDate).WidthPercent(50),
            new MultilineInput(entry.Text).Rows(16).Placeholder("Treść artykułu w formacie markdown"),
        )
    }
}
