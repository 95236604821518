import { RefBool, RefNumber } from "@tblabs/truffle";
import { RawRentPrice } from "./RawRentPrice";


export class RentPrice
{
    public Value = new RefNumber();
    public Days = new RefNumber();
    public Visible = new RefBool();

    constructor(raw: Partial<RawRentPrice>)
    {
        this.Value.value = raw.Value || 0;
        this.Days.value = raw.Days || 0;
        this.Visible.value = raw.Visible || false;
    }
}
