export enum PaymentStatus
{
    Unset = "Unset",
    Planned = "Planned",
    Awaiting = "Awaiting",
    Paid = "Paid",
    Canceled = "Canceled",
    Halted = "Halted",
    Quequed = "Quequed",
    InProgress = "InProgress"
}
