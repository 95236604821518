import { Link } from "@tblabs/truffle";


export class HashLink extends Link
{
    constructor(label: string, hash?: string)
    {
        super(label, hash ? "#" + hash : "");

        this.Class("hash-link")
    }
}
