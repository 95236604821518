import { Div, Image, Link, Span } from "@tblabs/truffle";
import { RawProductInfo } from "../../Models/OrderTicket/RawProductInfo";


export class ProductView extends Div
{
    constructor(product: Partial<RawProductInfo>)
    {
        super();

        this.Append(
            new Image(product.Picture).Height(44).Width(66).Margin(8).FloatLeft(),
            new Div().TextAlignLeft().FloatLeft().MarginTop(8).Append(
                new Div().Append(
                    new Link(product.Name || "", product.Link)
                ),
                new Span(product.Price).Color("#999").FontSize(12)
            )
        )
    }
}
