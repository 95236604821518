import { RawProductInfo } from "../OrderTicket/RawProductInfo";


export class SummaryBasketItem
{
    public Type = "";
    public Product: Partial<RawProductInfo>;
    public Cost = (-1);
    public Quantity = 1;
    public RentStart: Date;
    public RentEnd: Date;
}
