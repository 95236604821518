import { RefSerializer } from "@tblabs/truffle";
import { OrderTicket } from "../../Components/OrderTicket";
import { StorageResponse } from "../Storage/Models/StorageResponse";
import { OnlineStorage } from "../Storage/OnlineStorage";
import { UpdateCommand } from "./UpdateCommand";
import { TicketCreator } from "../TicketCreator";
import { BasketSummary } from "../../Models/Basket/BasketSummary";



export class TicketSender
{
    private FOLDER = "SpecteamOrders";

    constructor(
        private _storage: OnlineStorage) { }

    public async Send(order: OrderTicket): Promise<StorageResponse>
    {
        const file = order.Id;
        const raw = RefSerializer.Flatenize<object>(order);
        const result = await this._storage.SendMessage(new UpdateCommand(file, this.FOLDER, raw, true));

        return result;
    }
}

export class TicketerResult
{
    public IsSuccess: boolean = false;
    public ErrorMessage: string = "";

    constructor(isSuccess: boolean, errorMessage?: string)
    {
        this.IsSuccess = isSuccess;
        this.ErrorMessage = errorMessage || "";
    }
}

export class Ticketer
{
    constructor(
        private _creator: TicketCreator,
        private _sender: TicketSender)
    { }

    public async Send(summary: BasketSummary): Promise<TicketerResult>
    {
        const ticket = this._creator.CreateTicket(summary);
        console.log(ticket)
        const storageResult = await this._sender.Send(ticket);

        return new TicketerResult(storageResult.IsSuccess, storageResult.ErrorMessage);
    }
}