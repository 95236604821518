import { Div, RefBool, Link, Span, Label, Line, Button } from "@tblabs/truffle";
import { MiniBasketBox } from "../../Components/MiniBasket/MiniBasketBox";
import { Center } from "../../Components/Utils/Center";
import { Basket } from "../../Services/Basket/Basket";
import { HashLink } from "../../Components/Utils/HashLink";



export class PageHeader extends Div
{
    protected body = new Div();

    constructor(_basket: Basket)
    {
        super("page-header");

        const showMiniBasket = new RefBool();

        this.body.Class("container").Append(
            new Div("left").Append(
                new HashLink("Oferta", "/"),
                new HashLink("Kontakt", "kontakt"),
                new HashLink("O nas", "o-nas"),
            ),
            new Div("right").Append(
                new HashLink("☎️ 507 293 714", "kontakt"),
                new Label(_basket.Products.CountRef, v => `🛒 Koszyk (${v})`).MarginLeft(32)
                //.FloatRight()
                .Color("#f8f8f8").CursorPointer()
                .OnClick(() => showMiniBasket.Toggle()),
                new Div().Style(`position: absolute; top: 38px; right: 0px; z-index: 100; background: #f6f6f6; color: #333; height: auto; border: 2px solid #ccc; text-align: left; padding: 16px;`).Visible(showMiniBasket)
                .Append(
                    new Span("🛒 Koszyk").Italic().FontSize(24),
                    new Link("✖️").Style(`position: absolute; top: 4px; right: 4px;`)
                    .OnClick(() => showMiniBasket.False()),
                    new MiniBasketBox(_basket),
                    new Line(),
                    new Center(
                        // new Span("Koszt wypożyczenia jest zależny od jego terminów, cenę można policzyć w następnym kroku...").Italic(),
                        new Button("Zobacz koszyk").Class("selected").Margin(8)
                        .EnableWhen(_basket.Products.CountRef, c => +c > 0)
                        .OnClick(() =>
                            {
                                showMiniBasket.False();
                                window.location.hash = "basket";
                            })
                        )
                    )
                ),
        )

        this.Append(this.body);
    }
}
