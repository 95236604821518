import { Collection, CollectionPrinter, Div, Link } from "@tblabs/truffle";
import { FaqField } from "../../../Models/Description/Faq/FaqField";
import { FaqEntryEditor } from "./FaqEntryEditor";
import { Field } from "../../../Models/Description/Field";
import { Faq } from "../../../Models/Description/Faq/Faq";
import { FieldEditor } from "../../Utils/FieldEditor";


export class FaqsEditor extends FieldEditor
{
    constructor(field: FaqField, fields: Collection<Field>)
    {
        super("FAQ", field, fields, [
            new CollectionPrinter(field.Questions, x => new FaqEntryEditor(x, field.Questions)),
            new Div().ClearBoth(),
            new Link("➕ Q&A").OnClick(() => field.Questions.Add(new Faq({ Question: "", Answer: "" }))),
        ]);
    }
}
