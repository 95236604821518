import { BasketItemType } from "../../Services/Basket/BasketItemType";
import { BasketItem } from "./BasketItem";
import { RawSaleBasketItem } from "./RawSaleBasketItem";


export class SaleBasketItem extends BasketItem
{
    constructor(raw: Partial<RawSaleBasketItem>)
    {
        super(raw.ProductId, raw.Quantity);

        this.Type = BasketItemType.Sale;
    }

    public get TypeAsString(): string
    {
        return "Zakup";
    }
}
