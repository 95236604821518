import { Div } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";


export class Row extends Div
{
    protected readonly Content = new Div("centered")

    public Insert(...cmps: appendable[]): this
    {
        this.Content.Append(...cmps);

        return this;
    }

    constructor(...components: appendable[])
    {
        super("row");

        this.Append(
            this.Content
        )

        this.Insert(...components)
    }
}
